/* eslint-disable @typescript-eslint/naming-convention */

import { IQuestion, IResult } from '../Models'
import { shuffleArray } from '../Utils'

function formatQuestion (result: IResult): IQuestion {
  const {
    category,
    difficulty,
    correct_answer,
    incorrect_answers,
    question,
    type
  } = result

  const answers = incorrect_answers?.map((a: string) => {
    return {
      answer: a,
      isCorrect: false
    }
  })

  answers.push({
    answer: correct_answer,
    isCorrect: true
  })

  shuffleArray(answers)

  return {
    question,
    answers,
    type,
    category,
    difficulty
  }
}

const QuestionService = {
  getQuestions: async (): Promise<IQuestion[]> => {
    const result = await fetch('https://opentdb.com/api.php?amount=10')
    const json = await result.json()
    const questions = json.results.map((result: IResult) =>
      formatQuestion(result)
    )
    return questions
  }
}

export { QuestionService }
