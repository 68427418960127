import { FunctionComponent } from 'react'
import { Badge } from 'react-bootstrap'

interface ICountsHeaderProps {
  correct: number
  incorrect: number
  skipped: number
}

interface ICountProps {
  count: number
}

interface ICountLabelProps {
  bg: string
  text: string
  count: number
}

const CountLabel: FunctionComponent<ICountLabelProps> = (props) => {
  return (<Badge pill bg={props.bg}><>{props.text}: {props.count}</></Badge>)
}

const CorrectCount: FunctionComponent<ICountProps> = (props) => {
  return <CountLabel bg="success" text='Correct' count={props.count} />
}

const IncorrectCount: FunctionComponent<ICountProps> = (props) => {
  return <CountLabel bg="warning" text='Incorrect' count={props.count} />
}

const SkippedCount: FunctionComponent<ICountProps> = (props) => {
  return <CountLabel bg="secondary" text='Skipped' count={props.count} />
}

const CountsHeader: FunctionComponent<ICountsHeaderProps> = (props) => {
  return (
        <>
            <div className="countsHeader">
                <CorrectCount count={props.correct} />{' '}
                <IncorrectCount count={props.incorrect} />{' '}
                <SkippedCount count={props.skipped} />
            </div>
        </>
  )
}

export default CountsHeader
