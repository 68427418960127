import { Fragment, FunctionComponent, useState } from 'react'
import { Fade } from 'react-bootstrap'
import { IAnswer, ICounts, IQuestion } from '../Models'
import CountsHeader from './CountsHeader'
import QuestionMetaFooter from './QuestionMetaFooter'
import AnswerButton from './AnswerButton'
import NextQuestionButton from './NextQuestionButton'
import QuestionText from './QuestionText'

export interface IDisplayQuestionProps extends IQuestion {
  isLoading: boolean
  counts: ICounts
  updateAnswerCounts: (isCorrect: boolean | null) => void
  getQuestion: () => void
}

const DisplayQuestion: FunctionComponent<IDisplayQuestionProps> = (props) => {
  const [isAnswerSelected, setIsAnswerSelected] = useState<boolean>(false)

  const currentQuestion = props
  const { isLoading, counts, updateAnswerCounts, getQuestion } = props

  const onAnswerButtonClickHandler = (answer: IAnswer, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setIsAnswerSelected(true)
    updateAnswerCounts(answer.isCorrect);
    (event.target as HTMLButtonElement).style.border = '2px solid white'
  }

  const skipQuestionButtonClickHandler = (): void => {
    if (!isAnswerSelected) {
      updateAnswerCounts(null)
    }
    getQuestion()
    setIsAnswerSelected(false)
  }

  // ? Create AnswerButtonList component to wrap answer buttons

  return (
    <>
        <CountsHeader {...counts} />
        <Fade in={!isLoading}>
            <div>
                <QuestionText question={currentQuestion.question}/>
                {currentQuestion.answers.map((a, index) => (
                    <Fragment key={`${index}_${a.answer}`}>
                        <AnswerButton
                            answer={a.answer}
                            isCorrect={a.isCorrect}
                            isAnswerSelected={isAnswerSelected}
                            onAnswerButtonClickHandler={onAnswerButtonClickHandler} />
                    </Fragment>))
                }
                <NextQuestionButton
                    isAnswerSelected={isAnswerSelected}
                    buttonClickHandler={skipQuestionButtonClickHandler} />
            </div>
        </Fade>
        <br />
        <QuestionMetaFooter {...currentQuestion} />
    </>
  )
}

export default DisplayQuestion
