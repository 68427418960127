import { FunctionComponent } from 'react'
import { Button } from 'react-bootstrap'
import parse from 'html-react-parser'
import { IAnswer } from '../Models'

interface IAnswerButtonProps extends IAnswer {
  isAnswerSelected: boolean
  onAnswerButtonClickHandler: (answer: IAnswer, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const AnswerButton: FunctionComponent<IAnswerButtonProps> = (props) => {
  const { answer, isCorrect, isAnswerSelected, onAnswerButtonClickHandler } = props
  return (<div>
    <Button
        variant={
            isAnswerSelected
              ? isCorrect
                ? 'success'
                : 'secondary'
              : 'primary'
        }
        size="lg"
        className="mb-3"
        onClick={(e) => onAnswerButtonClickHandler(props, e)}
        disabled={isAnswerSelected}
    >
        {parse(String(answer))}
    </Button>
    <br />
</div>)
}

export default AnswerButton
