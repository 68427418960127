import { FunctionComponent } from 'react'
import { Button } from 'react-bootstrap'

interface INextQuestionButtonProps {
  isAnswerSelected: boolean
  buttonClickHandler: () => void
}

const NextQuestionButton: FunctionComponent<INextQuestionButtonProps> = (props) => {
  const { isAnswerSelected, buttonClickHandler } = props

  return (<Button
        variant="warning"
        size="lg"
        className="mt-4"
        onClick={buttonClickHandler}
    >
        {isAnswerSelected
          ? 'Next Question'
          : 'Skip Question'}
    </Button>)
}

export default NextQuestionButton
