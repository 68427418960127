import { FunctionComponent } from 'react'
import { Spinner } from 'react-bootstrap'

const Loader: FunctionComponent = () => {
  return (<>
        <h1>Loading Questions</h1>
        <Spinner animation="border" role="status">
            <span className="visually-hidden">...</span>
        </Spinner>
    </>)
}

export default Loader
