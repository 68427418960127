import { FunctionComponent } from 'react'
import { Badge } from 'react-bootstrap'

interface IQuestionMetaFooterProps {
  category: string
  type: string
  difficulty: string
}

const QuestionMetaFooter: FunctionComponent<IQuestionMetaFooterProps> = (props) => {
  return (
        <div className="questionMetaFooter">
            <div>
                <Badge bg="secondary">Category: {props.category}</Badge>{' '}
                <Badge bg="secondary">Type: {props.type}</Badge>{' '}
                <Badge bg="secondary">Difficulty: {props.difficulty}</Badge>
            </div>
        </div>
  )
}

export default QuestionMetaFooter
