/* eslint-disable @typescript-eslint/naming-convention */
import { FunctionComponent, useEffect, useState } from 'react'
import { QuestionService } from './services/QuestionService'
import { IQuestion, ICounts } from './Models'
import Loader from './components/Loader'
import DisplayQuestion, { IDisplayQuestionProps } from './components/DisplayQuestion'
import './App.css'

const initialCounts: ICounts = {
  correct: 0,
  incorrect: 0,
  skipped: 0
}

const App: FunctionComponent = () => {
  const [currentQuestion, setCurrentQuestion] = useState<IQuestion | null>(null)
  const [questionList, setQuestionList] = useState<IQuestion[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [counts, setCounts] = useState<ICounts>(initialCounts)

  useEffect(() => {
    getQuestion()
  }, [])

  const getQuestion = (): void => {
    const nextQuestion = questionList.pop()

    if (nextQuestion != null) {
      setQuestion(nextQuestion)
    }

    if (questionList.length === 0) {
      const isPreload = currentQuestion !== null
      loadQuestionList(isPreload)
    }
  }

  const setQuestion = (q: IQuestion): void => {
    setCurrentQuestion(q)
  }

  const loadQuestionList = (isPreload: boolean = false): void => {
    if (!isPreload) {
      setIsLoading(true)
    }

    QuestionService.getQuestions()
      .then(questions => {
        setQuestionList(questions)

        if (!isPreload) {
          const currentQuestion = questions.pop()
          if (currentQuestion !== undefined) {
            setQuestion(currentQuestion)
          }
        }
      })
      .catch((error) => {
        console.log(error)

        setCurrentQuestion(null)
        setQuestionList([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateAnswerCounts = (isCorrect: boolean | null): void => {
    switch (isCorrect) {
      case true:
        setCounts((x) => ({ ...x, correct: x.correct + 1 }))
        break
      case false:
        setCounts((x) => ({ ...x, incorrect: x.incorrect + 1 }))
        break
      case null:
        setCounts((x) => ({ ...x, skipped: x.skipped + 1 }))
        break
    }
  }

  const displayQuestionProps: IDisplayQuestionProps = {
    ...currentQuestion as IQuestion,
    isLoading,
    counts,
    getQuestion,
    updateAnswerCounts
  }

  return (
        <div className="App">
            <header className="App-header">
                {
                    isLoading || (currentQuestion == null)
                      ? <Loader/>
                      : <DisplayQuestion {...displayQuestionProps} />
                }
            </header>
        </div>
  )
}

export default App
